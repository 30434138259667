import React, { useState } from "react";
import Footer from "../widgets/Footer";
import Navbar from "../widgets/Navbar";
import Sidebar from "../widgets/Sidebar";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import {
  useGetCopierProfileQuery,
  useAddInvestmentMutation,
  useDecreaseInvestmentMutation,
  useStopSubscriptionMutation,
  useGetCopierCloseTradesQuery,
  useGetUserCopyBalanceQuery,
  useGetCopierOpenTradesQuery,
} from "../../redux/userApi";
import { imageUrl } from "../constant/BaseUrl";
import { Modal } from "react-bootstrap";
import moment from "moment";
import BigNumber from "bignumber.js";
import { formatDecimal } from "../../util/decimalFormatter";
import ProfitLossBar from "../partials/ProfitLossBar";

const CopierArea = () => {
  const { t } = useTranslation();
  const { copierUserId, masterUserId } = useParams();
  const [activeTradeTab, setActiveTradeTab] = useState("OPEN");
  const [activeTab, setActiveTab] = useState("SUMMARY");
  const [isModelShow, setIsModelShow] = useState();
  const [type, setType] = useState("invest");
  const [text, setText] = useState("Add Investment");
  const [amount, setAmount] = useState("");

  const { data, isLoading, refetch } = useGetCopierProfileQuery(
    { copierUserId, masterUserId },
    { skip: !copierUserId }
  );
  const { data: balanceData = [], refetch: refetchBalances } =
    useGetUserCopyBalanceQuery(
      { userId: copierUserId, masterUserId },
      { skip: !copierUserId }
    );
  const {
    data: copierData = [],
    isLoading: isCopierDataLoad,
    refetch: copyDataRefetch,
  } = useGetCopierCloseTradesQuery(
    { copierUserId, masterUserId },
    { skip: !copierUserId }
  );

  const {
    data: copierOpenData = [],
    // isLoading,
    refetch: copyOpenDataRefetch,
  } = useGetCopierOpenTradesQuery(
    { copierUserId, masterUserId },
    { skip: !copierUserId }
  );
  const [addInvestment, { isLoading: isInvesting }] =
    useAddInvestmentMutation();
  const [decreaseInvestment, { isLoading: isDecreasing }] =
    useDecreaseInvestmentMutation();
  const [stopSubscription, { isLoading: isStop }] =
    useStopSubscriptionMutation();
  console.log(copierOpenData, "copierOpenData");
  if (isLoading) return;

  const copierCloseOrdersTable = () => {
    if (copierData.length > 0) {
      return copierData.map((item, i) => {
        const grossProfitLoss = new BigNumber(item.pnl);

        const netProfitLoss = grossProfitLoss.minus(item.masterCommission);
        return (
          <tr>
            {/* <td>{i + 1}</td> */}
            <td>{item.master_name}</td>
            <td>{item.segment}</td>
            <td>{item.symbol}</td>
            <td>{item.price}</td>
            <td>{item.closed_price}</td>
            <td>{item.quantity}</td>
            <td>{moment(item.created_at).format("lll")}</td>
            <td>{moment(item.updated_at).format("lll")}</td>
            <td className="text-danger">{item.masterCommission}</td>
            <td
              className={`text-right ${
                netProfitLoss < 0 ? "text-danger" : "text-success"
              }`}
            >
              ${formatDecimal(netProfitLoss)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7}>No Data Found</td>
        </tr>
      );
    }
  };

  const copierOpenOrdersTable = () => {
    if (copierOpenData.length > 0) {
      return copierOpenData.map((item, i) => {
        return (
          <tr>
            {/* <td>{i + 1}</td> */}
            <td>{data.nickName}</td>
            <td>{item.segment}</td>
            <td>{item.symbol}</td>
            <td>{item.price}</td>

            <td>{item.quantity}</td>
            <td>{moment(item.open_time).format("lll")}</td>
            {/* <td>#{item.id}</td> */}
            <td
              className={`text-right ${
                item.total_floating_profit < 0 ? "text-danger" : "text-success"
              }`}
            >
              ${formatDecimal(item.total_floating_profit)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7}>No Data Found</td>
        </tr>
      );
    }
  };

  const balanceTable = () => {
    if (balanceData.length > 0) {
      return balanceData.map((item, i) => {
        return (
          <tr>
            {/* <td>{i + 1}</td> */}
            <td className="ttu">{item.operation_type}</td>
            <td>{moment(item.created_at).format("lll")}</td>

            {/* <td>#{item.id}</td> */}
            <td
              className={`text-right ${
                item.amount < 0 ? "text-danger" : "text-success"
              }`}
            >
              ${formatDecimal(item.amount)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7}>No Data Found</td>
        </tr>
      );
    }
  };

  const closeModal = (type) => {
    setAmount("");
    console.log(type, "type888");
    setType(type);
    const title =
      type === "Invest"
        ? "Add Investment"
        : type === "Reduce"
        ? "Reduce Investment"
        : "Stop Subscription";
    setText(title);
    setIsModelShow((prev) => !prev);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "SUMMARY":
        return (
          <>
            <div className="p-md-4 p-3 bg2 br20 mb-4">
              <div className="master-profile rating-card-header">
                <div className="mpa">
                  <img
                    src={
                      data.profileImage
                        ? imageUrl +
                          "/static/masterProfileImages/" +
                          data.profileImage
                        : "/img/Default_pfp.png"
                    }
                    className="profile_avatar-flag"
                    alt="profile master"
                  />
                </div>
                <div className="master-profile__info">
                  <span className="master-profile__master-nickname text-body-2">
                    {" "}
                    {data.nickName}{" "}
                  </span>
                  <div className="small">
                    <i className="bi bi-star color1 mr-2"></i> {data.level}
                  </div>
                </div>
              </div>
              <div className="d-md-flex justify-content-between row">
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Profit </h6>
                  <div className="ccpv mb-2">${data.pnl}</div>
                </div>
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Floating Profit </h6>
                  <div className="ccpv mb-2">${data.floatingProfit} </div>
                </div>
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Gains </h6>
                  <div className="ccpv mb-2">{data.gains}% </div>
                </div>
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Commission </h6>
                  <div className="ccpv mb-2">{data.commission}% </div>
                </div>
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Equity </h6>
                  <div className="ccpv mb-2">${data.equity.toFixed(2)} </div>
                </div>
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Balance </h6>
                  <div className="ccpv mb-2">${data.balance} </div>
                </div>
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Leverage </h6>
                  <div className="ccpv mb-2">{data.leverage} </div>
                </div>
                <div className="mb-3 d-md-block d-flex align-items-center col-md-6 col-sm-6 col-lg-3 mt-4">
                  <h6 className="light_color mr-auto">Risk Score </h6>
                  <div className="ccpv mb-2">1</div>
                </div>
                <ProfitLossBar
                  pnl={data.pnl}
                  totalDeposit={data.totalDeposit}
                  balance={data.balance}
                />
              </div>
            </div>
          </>
        );
      case "TRADES":
        return (
          <div className="p-md-4 p-3 bg2 br20 mb-4">
            <div className="d-flex justify-content-center mb-4 position_tab d-md-flex mb-4 h4 fs18 ttu">
              <a
                className={` ${activeTradeTab === "OPEN" ? "active" : ""}`}
                onClick={() => setActiveTradeTab("OPEN")}
              >
                Open Orders
              </a>
              <a
                className={` ml-2 ${
                  activeTradeTab === "CLOSE" ? "active" : ""
                }`}
                onClick={() => setActiveTradeTab("CLOSE")}
              >
                Closed Orders
              </a>
              <a
                className={` ml-2 ${
                  activeTradeTab === "BALANCE" ? "active" : ""
                }`}
                onClick={() => setActiveTradeTab("BALANCE")}
              >
                Balance History
              </a>
            </div>

            <div className="table-responsive trade_scrool_table ">
              {activeTradeTab === "OPEN" ? (
                <div>
                  <h5 className="nav-item mb-2">Open Orders</h5>
                  <table className="table">
                    <thead>
                      <tr className="ttu">
                        <th>Master Username</th>
                        <th>Segment</th>
                        <th>Symbol</th>
                        <th>Open price</th>
                        <th>Quantity</th>
                        <th>Open time</th>
                        <th className="text-right">P&L</th>
                      </tr>
                    </thead>
                    <tbody>{copierOpenOrdersTable()}</tbody>
                  </table>
                </div>
              ) : activeTradeTab === "CLOSE" ? (
                <div>
                  <h5 className="nav-item mb-2">Closed Orders</h5>
                  <table className="table">
                    <thead>
                      <tr className="ttu">
                        <th>Master Username</th>
                        <th>Segment</th>
                        <th>Symbol</th>
                        <th>Open price</th>
                        <th>Close price</th>
                        <th>Quantity</th>
                        <th>Open time</th>
                        <th>Close time</th>
                        <th>Master commission</th>
                        <th className="text-right">GP&L</th>
                      </tr>
                    </thead>
                    <tbody>{copierCloseOrdersTable()}</tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <h5 class="nav-item mb-2">Balance History</h5>

                  <table className="table">
                    <thead>
                      <tr className="ttu">
                        {/* <th>S. No.</th> */}
                        <th>Type</th>
                        <th>Date</th>
                        {/* <th>Order ID</th> */}
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>{balanceTable()}</tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        );
      case "OPTIONS":
        return (
          <div className="p-md-4 p-3 bg2 br20 mb-4">
            <a
              href="#!"
              className="d-flex opions_link"
              onClick={() => closeModal("Invest")}
            >
              <i className="bi bi-database-add mr-3 ol_icon"></i> Add investment
              <i className="bi bi-chevron-right ml-auto"></i>
            </a>
            <a
              href="#!"
              className="d-flex opions_link"
              onClick={() => closeModal("Reduce")}
            >
              <i className="bi bi-dash-circle mr-3 ol_icon"></i> Reduce
              investment
              <i className="bi bi-chevron-right ml-auto"></i>
            </a>
            {/* <a href="#!" className="d-flex opions_link">
              <i className="bi bi-gear mr-3 ol_icon"></i> Change copy proportion
              <i className="bi bi-chevron-right ml-auto"></i>
            </a> */}
            <a
              href="#!"
              className="d-flex opions_link"
              onClick={() => closeModal("Stop")}
            >
              <i className="bi bi-sign-stop mr-3 ol_icon"></i> Stop copying
              <i className="bi bi-chevron-right ml-auto"></i>
            </a>
            <a
              href={`/master-profile/${masterUserId}`}
              className="d-flex opions_link"
              // onClick={() => closeModal("performance")}
            >
              <i className="bi bi-grid mr-3 ol_icon"></i> View Master's
              performance
              <i className="bi bi-chevron-right ml-auto"></i>
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  const handleOptions = async () => {
    try {
      let response;

      if (
        (type === "Invest" || type === "Reduce") &&
        (!amount || amount <= 0)
      ) {
        toast.error(t("Please enter a valid amount."));
        return;
      }

      if (type === "Invest" && (!amount || amount < data.minimumInvestment)) {
        toast.error(
          t(`Minimum amount must be greater than ${data.minimumInvestment}`)
        );
        return;
      }

      const body = {
        copierUserId,
        masterUserId,
        ...(type !== "Stop" && { amount: parseFloat(amount) }),
      };

      if (type === "Invest") {
        response = await addInvestment(body);
      } else if (type === "Reduce") {
        response = await decreaseInvestment(body);
      } else if (type === "Stop") {
        response = await stopSubscription(body);
      }

      if (response.data?.status) {
        toast.dismiss();
        toast.success(t(response.data.message));
        refetch();
        copyDataRefetch();
        refetchBalances();
        setTimeout(() => {
          setIsModelShow(false);
          setAmount("");
          // navigate("/copier-area");
        }, 300);
      } else {
        toast.dismiss();
        toast.error(t(response.data?.message || "An error occurred."));
      }
    } catch (error) {
      toast.dismiss();
      toast.error(t("An unexpected error occurred. Please try again later."));
    }
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <h3 className="mb-3">Your investment with the Master</h3>

              <div className="position_tab d-md-flex mb-4 h4 fs18 ttu">
                <a
                  href="#!"
                  className={activeTab === "SUMMARY" ? "active" : ""}
                  onClick={() => setActiveTab("SUMMARY")}
                >
                  SUMMARY
                </a>
                <a
                  href="#!"
                  className={activeTab === "TRADES" ? "active" : ""}
                  onClick={() => setActiveTab("TRADES")}
                >
                  TRADES
                </a>
                <a
                  href="#!"
                  className={activeTab === "OPTIONS" ? "active" : ""}
                  onClick={() => setActiveTab("OPTIONS")}
                >
                  OPTIONS
                </a>
              </div>

              {renderTabContent()}
            </div>
          </div>
        </div>
        <Modal
          show={isModelShow}
          onHide={closeModal}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="mb-0"> {t(text)}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeModal}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <section className="pb-md-2">
              <div className="container ">
                <div className="row">
                  <div className="col-md-12 py-3 bg2 br20">
                    <div className="row text-left">
                      <div className="mpa mpa2 col-md-2">
                        <img
                          className="profile_avatar-flag"
                          alt="profile avatar"
                          src={
                            data.profileImage
                              ? imageUrl +
                                "/static/masterProfileImages/" +
                                data.profileImage
                              : "/img/Default_pfp.png"
                          }
                        />
                      </div>
                      <div className="pt-3 col-md-8">
                        <h5 className="mb-1">{data.nickName} </h5>
                      </div>
                    </div>

                    <hr />

                    {type !== "Stop" && (
                      <div className="d-flex mb-3 justify-content-between">
                        <span>Amount</span>
                        <span>
                          <input
                            type="number"
                            placeholder="$"
                            className="form-control"
                            value={amount}
                            onChange={(e) => {
                              if (
                                (type === "Invest" || type === "Reduce") &&
                                (!e.target.value || e.target.value <= 0)
                              ) {
                                toast.error(t("Please enter a valid amount."));
                              }
                              setAmount(e.target.value);
                            }} // Bind input to state
                          />
                        </span>
                      </div>
                    )}
                    {type === "Stop" ? (
                      <div className="d-flex mb-3 justify-content-between">
                        <span>
                          Please confirm you want to stop copying this master?
                        </span>
                      </div>
                    ) : (
                      type === "Invest" && (
                        <div className="d-flex mb-3 justify-content-between">
                          <span></span>
                          <span>Minimum Amount: ${data.minimumInvestment}</span>
                        </div>
                      )
                    )}

                    <hr />

                    <div className="text-right">
                      <button
                        className="btn pl-4 pr-4"
                        onClick={handleOptions}
                        disabled={isInvesting || isDecreasing || isStop}
                      >
                        {isInvesting || isDecreasing || isStop
                          ? "Confirming..."
                          : "Confirm"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section>
      <Footer />
    </div>
  );
};

export default CopierArea;
