import { myApi } from "./api";
import {
  cancleOrderFx,
  closePosition,
  createOrderFutures,
  modifyOrderClick,
} from "../components/constant/Api";

export const ForexApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrderClickFutures: builder.mutation({
      query: (post) => ({
        url: createOrderFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    modifyOrderClickFutures: builder.mutation({
      query: (post) => ({
        url: modifyOrderClick,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    closePositionFutures: builder.mutation({
      query: (post) => ({
        url: closePosition,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    cancleOrderFutures: builder.mutation({
      query: (post) => ({
        url: cancleOrderFx,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
  }),
});

export const { useCreateOrderClickFuturesMutation } = ForexApi;
