import {
  changeStatusAuth,
  googleCodeGenerate,
  loginVerify2fa,
  userInfo,
  submitKyc,
  userActivity,
  resetPassword,
  getCryptoPortfolio,
  widgetPermissions,
  createMasterProfile,
  updateMasterProfile,
  createSubscription,
  subscribedMasters,
  copiers,
  addInvestment,
  decreaseInvestment,
  stopSubscription,
  mastersMetrics,
  copierBalanceHistory,
  masters,
  getCopierCloseTrades,
  getCopierOpenTrades,
  getMastersOpenTrades,
  getMastersCloseTrades,
  masterBalanceHistory,
} from "../components/constant/Api";
import { myApi } from "./api";

export const UserApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserWidgets: builder.query({
      query: ({ type }) => ({
        url: `${widgetPermissions}/${type}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getallUser: builder.query({
      query: () => ({
        url: userInfo,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getUserActivity: builder.query({
      query: () => ({
        url: userActivity,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    generateGoogleCode: builder.query({
      query: () => ({
        url: googleCodeGenerate,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getCryptoPortfolio: builder.query({
      query: () => ({
        url: getCryptoPortfolio,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    changeStatus2Fa: builder.mutation({
      query: (post) => ({
        url: changeStatusAuth,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    verifyLoginCode: builder.mutation({
      query: (post) => ({
        url: loginVerify2fa,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    submitKyc: builder.mutation({
      query: (post) => ({
        url: submitKyc,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    changePassword: builder.mutation({
      query: (post) => ({
        url: resetPassword,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    createMasterProfile: builder.mutation({
      query: (post) => ({
        url: createMasterProfile,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    updateMasterProfileImage: builder.mutation({
      query: (post) => ({
        url: updateMasterProfile,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    getMasterProfile: builder.query({
      query: ({ masterUserId }) => ({
        url: `${masters}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getMasters: builder.query({
      query: () => ({
        url: `${masters}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getMasterCloseTrades: builder.query({
      query: ({ masterUserId }) => ({
        url: `${getMastersCloseTrades}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    createSubscription: builder.mutation({
      query: (post) => ({
        url: createSubscription,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    getSubsMasters: builder.query({
      query: ({ copierUserId }) => ({
        url: `${subscribedMasters}/${copierUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getCopierProfile: builder.query({
      query: ({ copierUserId, masterUserId }) => ({
        url: `${copiers}/${copierUserId}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getCopierBalanceHistory: builder.query({
      query: ({ copierUserId, masterUserId }) => ({
        url: `${copiers}/${copierUserId}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    addInvestment: builder.mutation({
      query: (post) => ({
        url: addInvestment,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    decreaseInvestment: builder.mutation({
      query: (post) => ({
        url: decreaseInvestment,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    stopSubscription: builder.mutation({
      query: (post) => ({
        url: stopSubscription,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    getMastersMetrics: builder.query({
      query: ({ masterUserId, period }) => ({
        url: `${mastersMetrics}?masterUserId=${masterUserId}&period=${period}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getCopierCloseTrades: builder.query({
      query: ({ copierUserId, masterUserId }) => ({
        url: `${getCopierCloseTrades}/${copierUserId}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getCopierOpenTrades: builder.query({
      query: ({ copierUserId, masterUserId }) => ({
        url: `${getCopierOpenTrades}/${copierUserId}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getMastersOpenTrades: builder.query({
      query: ({ masterUserId }) => ({
        url: `${getMastersOpenTrades}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getMasterBalanceHistory: builder.query({
      query: ({ userId }) => ({
        url: `${masterBalanceHistory}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
    getUserCopyBalance: builder.query({
      query: ({ userId, masterUserId }) => ({
        url: `${copierBalanceHistory}/${userId}/${masterUserId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response.data ?? response.data : response;
      },
    }),
  }),
});
export const {
  useGetallUserQuery,
  useGenerateGoogleCodeQuery,
  useChangeStatus2FaMutation,
  useVerifyLoginCodeMutation,
  useSubmitKycMutation,
  useGetUserActivityQuery,
  useChangePasswordMutation,
  useGetCryptoPortfolioQuery,
  useGetUserWidgetsQuery,
  useCreateMasterProfileMutation,
  useGetMasterProfileQuery,
  useUpdateMasterProfileImageMutation,
  useGetMastersQuery,
  useCreateSubscriptionMutation,
  useGetSubsMastersQuery,
  useGetCopierProfileQuery,
  useGetCopierBalanceHistoryQuery,
  useAddInvestmentMutation,
  useDecreaseInvestmentMutation,
  useStopSubscriptionMutation,
  useGetMastersMetricsQuery,
  useGetCopierCloseTradesQuery,
  useGetUserCopyBalanceQuery,
  useGetCopierOpenTradesQuery,
  useGetMasterCloseTradesQuery,
  useGetMastersOpenTradesQuery,
  useGetMasterBalanceHistoryQuery,
} = UserApi;
