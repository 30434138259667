import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { imageUrl } from "../constant/BaseUrl";
import { useGetUserForexPortfolioMutation } from "../../redux/forexApi";
import { formatDecimal } from "../../util/decimalFormatter";
import { useCreateSubscriptionMutation } from "../../redux/userApi";
import { useSelector } from "react-redux";

const SubScriptionSetup = ({
  profileImage,
  masterName,
  commission,
  requiredAmount,
  masterUserId,
}) => {
  const token = localStorage.getItem("jwtToken");
  const [totalBalance, setTotalBalance] = useState(0.0);
  const { userId } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userPortfolioForex] = useGetUserForexPortfolioMutation();
  const [createSubscription, { isLoading }] = useCreateSubscriptionMutation();
  useEffect(() => {
    if (token) {
      const getEquityPortfolio = async () => {
        const data = {
          symbolType: "equity",
        };
        const res = await userPortfolioForex(data);
        const resp = res.data;
        if (resp.status === true) {
          setTotalBalance(resp.data.balance ? resp.data.balance : "0.0");
        }
      };
      getEquityPortfolio();
    }
  }, []);

  const handleSubscription = async () => {
    const body = {
      masterUserId,
      copierUserId: userId,
      allocatedBalance: requiredAmount,
    };

    const { data } = await createSubscription(body);

    if (data.status) {
      toast.dismiss();
      toast.success(t(data.message));
      setTimeout(() => {
        navigate(`/copier-area/${userId}/${masterUserId}`);
      }, 300);
    } else {
      toast.dismiss();
      toast.error(t(data.message));
    }
  };

  return (
    <div>
      {/* <Navbar /> */}
      <section className="pb-md-2">
        <div className="container ">
          <div className="row">
            <div className="col-md-12 py-3 bg2 br20">
              <div className="row text-left">
                <div className="mpa mpa2 col-md-2">
                  <img
                    className="profile_avatar-flag"
                    alt="profile avatar"
                    src={
                      profileImage
                        ? imageUrl +
                          "/static/masterProfileImages/" +
                          profileImage
                        : "/img/Default_pfp.png"
                    }
                  />
                </div>
                <div className="pt-3 col-md-8">
                  <h5 className="mb-1">{masterName} </h5>
                  <p className="light_color">{commission}% commission</p>
                </div>
              </div>
              <div className="d-flex  pt-2 justify-content-between">
                <div className=""> Funds in Wallet </div>
                <p className=" ">
                  {!token ? (
                    "Login to Deposit"
                  ) : (
                    <>
                      <i class="bi bi-wallet2 mr-1"></i> $
                      {formatDecimal(totalBalance)} USD
                    </>
                  )}
                </p>
              </div>
              <hr />
              {/* <div className="d-flex mt-3 mb-3 justify-content-between">
                <span>Copy proportion</span>
                <span>Equal x1</span>
              </div> */}
              <div className="d-flex mb-3 justify-content-between">
                <span>Required Investment </span>
                <span>${formatDecimal(requiredAmount)}</span>
              </div>
              {/* <div className="d-flex mb-3 justify-content-between">
                <span>Support funds </span>
                <span>$0.00</span>
              </div> */}
              <hr />
              <div className="d-flex mb-3 pt-1 justify-content-between">
                <b>Total</b>
                <span>${formatDecimal(requiredAmount)}</span>
              </div>
              <div className="text-right">
                {!token ? (
                  <Link to="/login" className="btn pl-4 pr-4">
                    Login Start Copying
                  </Link>
                ) : (
                  <button
                    className="btn pl-4 pr-4"
                    onClick={handleSubscription}
                    disabled={isLoading}
                  >
                    {isLoading ? "Creating Subscription..." : "Start Copying"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default SubScriptionSetup;
