import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const AreaChart = ({ allData }) => {
  console.log(allData, "all");

  const filterOptions = {
    "1 Month": 30,
    "3 Months": 90,
    "6 Months": 180,
    All: "All",
  };

  const [filteredData, setFilteredData] = useState(allData); // Initially show all data
  const [currentFilter, setCurrentFilter] = useState("1 Month");

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);

    if (filter === "All") return setFilteredData(allData);

    // Calculate the cutoff date based on the selected filter
    const daysToShow = filterOptions[filter];
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - daysToShow);

    // Filter the data based on the cutoff date
    const filtered = allData.filter(
      (data) => new Date(data.date) >= cutoffDate
    );
    setFilteredData(filtered);
  };

  const chartData = {
    labels: filteredData.map((d) => d.date),
    datasets: [
      {
        label: "PnL",
        data: filteredData.map((d) => d.pnl),
        fill: true,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.3, // Curve smoothing
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `PnL Over Time (${currentFilter})`,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const symbol = filteredData[index].symbol;
            const pnl = context.raw;
            return `Symbol: ${symbol}, PnL: ${pnl}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "PnL",
        },
      },
    },
  };

  return (
    <div>
      <Line options={options} data={chartData} />
      <div style={{ marginBottom: "20px" }}>
        {Object.keys(filterOptions).map((filter) => (
          <button
            key={filter}
            onClick={() => handleFilterChange(filter)}
            className="btn"
            style={{
              padding: "5px 10px",
              margin: "5px",
              backgroundColor: filter === currentFilter ? "#01d59b" : "#ddd",
              color: filter === currentFilter ? "#fff" : "#000",
              border: "none",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AreaChart;
