import React from "react";

const ProfitLossBar = ({ balance, totalDeposit, classHead = "" }) => {
  let profit = 0;
  let loss = 0;
  let profitPercentage = 0;
  let lossPercentage = 0;

  if (balance > totalDeposit) {
    profit = balance - totalDeposit;
    profitPercentage = ((profit / totalDeposit) * 100).toFixed(2);
  } else {
    loss = totalDeposit - balance;
    lossPercentage = ((loss / totalDeposit) * 100).toFixed(2);
  }

  // Adjust percentages based on the rule
  if (lossPercentage < 1) {
    lossPercentage = 1;
    profitPercentage = 100 - lossPercentage;
  } else {
    profitPercentage = 100 - lossPercentage;
  }

  // Round percentages to nearest whole number
  profitPercentage = Math.round(profitPercentage);
  lossPercentage = Math.round(lossPercentage);

  console.log({ profitPercentage, lossPercentage });

  return (
    <div className="col-lg-12 p-0">
      <h6 className={`light_color ${classHead}`}>Profit and Loss</h6>

      <div className="d-flex mt-2">
        <div className="color15 ccpv">
          ${profit > 0 ? profit.toFixed(2) : "0.00"}
        </div>
        <div className="ml-auto ccpv">${-loss.toFixed(2)}</div>
      </div>
      <div className="profit-and-loss-progress" style={{ display: "flex" }}>
        <div
          className="profit-and-loss-progress__item _profit"
          style={{
            flexBasis: `${profitPercentage}%`,
            backgroundColor: "#00aa32",
            height: "2px",
          }}
        />
        <div
          className="profit-and-loss-progress__item _loss"
          style={{
            flexBasis: `${lossPercentage}%`,
            backgroundColor: "#f5281e",
            height: "2px",
          }}
        />
      </div>
    </div>
  );
};

export default ProfitLossBar;
