import React, { useEffect, useState } from "react";
import Footer from "../widgets/Footer";
import Navbar from "../widgets/Navbar";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { imageUrl } from "../constant/BaseUrl";
// import { getMasters } from "../services/Login";
import { useGetMastersQuery } from "../../redux/userApi";
import { useSelector } from "react-redux";
import ProfitLossBar from "../partials/ProfitLossBar";
const Masters = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data = [], isLoading } = useGetMastersQuery();
  const { userData } = useSelector((state) => state.user);
  const [filters, setFilters] = useState({
    minimumInvestment: "",
    level: "",
    nickName: "",
  });
  console.log(data, "masters", userData);

  useEffect(() => {
    const { is_master_trader } = userData || "0";
    if (is_master_trader === "1") {
      navigate("/dashboard");
    }
  }, [userData, navigate]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const filteredData = data.filter((master) => {
    const minInvestMatch =
      filters.minimumInvestment === "" ||
      master.minimumInvestment >= Number(filters.minimumInvestment);

    const levelMatch =
      filters.level === "" ||
      master.level.toLowerCase() === filters.level.toLowerCase();

    const nickNameMatch =
      filters.nickName === "" ||
      master.nickName.toLowerCase().includes(filters.nickName.toLowerCase());

    return minInvestMatch && levelMatch && nickNameMatch;
  });

  if (isLoading) return;

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <h3 className="mb-3">Dynamic Tech</h3>

          <div className="p-md-4 p-3 bg2 br20 mb-4">
            <h5 className="mb-3">Master rating</h5>
            <div className="row">
              <div className="col-md-3">
                <label for="minimumInvestment">Minimum investment</label>
                <input
                  type="text"
                  className="form-control"
                  id="minimumInvestment"
                  name="minimumInvestment"
                  placeholder="25 or more"
                  required=""
                  value={filters.minimumInvestment}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-3">
                <label for="level">Minimum expertise </label>
                <select
                  className="form-control"
                  id="level"
                  name="level"
                  value={filters.level}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Starter">Starter</option>
                  <option value="Pro">Pro</option>
                  <option value="Elite">Elite</option>
                  <option value="Rookie">Rookie</option>
                </select>
              </div>
              <div className="col-md-3 ml-auto">
                <label for="nickName">Nickname </label>
                <div className="position-relative master_search">
                  <input
                    type="text"
                    placeholder="Nickname"
                    className="form-control pl-5"
                    id="nickName"
                    name="nickName"
                    value={filters.nickName}
                    onChange={handleFilterChange}
                  />
                  <i className="bi bi-search position-absolute"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="text-right">
            <span>{data ? data.length : 0} Master Traders shown</span>

            <b
              className="color1 ml-4 ttu cursor-pointer"
              onClick={() =>
                setFilters({
                  minimumInvestment: "",
                  level: "",
                  nickName: "",
                })
              }
            >
              Reset all
            </b>
          </div>

          <div className="row mt-4">
            {filteredData ? (
              filteredData.map((item) => (
                <div
                  className="col-md-4 col-sm-6 col-lg-3 mb-4"
                  key={item.masterUserId}
                >
                  <Link
                    to={`/master-profile/${item.masterUserId}`}
                    target="_blank"
                    className="rating-card bg2"
                  >
                    <div className="master-profile rating-card-header">
                      <div className="mpa">
                        <img
                          src={
                            item.profileImage
                              ? imageUrl +
                                "/static/masterProfileImages/" +
                                item.profileImage
                              : "img/Default_pfp.png"
                          }
                          className="profile_avatar-flag"
                          alt="profile master"
                        />
                      </div>
                      <div className="master-profile__info">
                        <span className="master-profile__master-nickname text-body-2">
                          {" "}
                          {item.nickName}{" "}
                        </span>
                        <div className="small">
                          <i className="bi bi-star color1 mr-2"></i>{" "}
                          {item.level}
                        </div>
                      </div>
                      {/* <div className="h_btn ml-auto">1 risk</div> */}
                    </div>
                    <div className="card-content">
                      <div className="card-content__performance">
                        <div className="ccpi2">
                          <div className="ccpi">Gain</div>
                          <div
                            className={`ccpv ${
                              item.gains > 0 ? "succes_s" : "text-danger"
                            }`}
                          >
                            {item.gains > 0 ? +item.gains : item.gains}%
                          </div>
                        </div>
                        <div className="ccpi2">
                          <div className="ccpi">Copiers</div>
                          <div className="ccpv">{item.copiersCount}</div>
                        </div>
                        <div className="ccpi2">
                          <div className="ccpi">Commission</div>
                          <div className="ccpv">{item.commissionRate}%</div>
                        </div>
                      </div>
                      <div className="profit-and-loss ccls mt-3">
                        <ProfitLossBar
                          pnl={item.pnl}
                          totalDeposit={item.totalDeposit}
                          balance={item.balance}
                          classHead="ccpi"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="p-md-4 p-3">No records found!</div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Masters;
