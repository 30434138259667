import React, { useCallback, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import Sidebar from "../widgets/Sidebar";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Editor from "@monaco-editor/react";
import { usePythonScriptMutation } from "../../redux/scriptApi";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const UploadScript = () => {
  const { t } = useTranslation();
  const [runScript] = usePythonScriptMutation();
  const [fileContent, setFileContent] = useState("");
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [executionResult, setExecutionResult] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Callback for handling dropped files
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setFileContent(reader.result); // Set the content of the file
        setIsEditorVisible(true); // Show the editor
      };
      reader.readAsText(file); // Read file content as text
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".py", // Accept only .py files
    multiple: false, // Single file upload
  });

  // Submit script to the backend for execution
  const handleSubmit = async () => {
    setIsSubmitting(true); // Disable button during submission
    try {
      const scriptObj = {
        scriptContent: fileContent,
        balance: 100000,
      };
      const res = await runScript(scriptObj);

      // Check for successful response and set execution result
      if (res.data.success) {
        setExecutionResult({
          success: true,
          message: res.data.message,
          details: res.data.results.map((result) => result.message), // Extract messages for display
        });
      } else {
        setExecutionResult({
          success: false,
          message: res.data.message || "Unknown error occurred.",
          details: [],
        });
      }
    } catch (error) {
      console.error("Error running script:", error);
      setExecutionResult({
        success: false,
        message: "Failed to execute script. Please try again.",
        details: [],
      });
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3 bg2 br20 mb-4 h100">
                <h5 className="mb-2">{t("For You")}</h5>

                {/* Show Dropzone if editor is not visible */}
                {!isEditorVisible ? (
                  <div
                    {...getRootProps()}
                    className={`dropzone p-4 border border-dashed rounded ${
                      isDragActive ? "active" : ""
                    }`}
                    style={{
                      border: "2px dashed #01d59b",
                      borderRadius: "10px",
                      padding: "20px",
                      textAlign: "center",
                    }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the .py file here...</p>
                    ) : (
                      <p>Drag & drop a .py file here, or click to select one</p>
                    )}
                  </div>
                ) : (
                  // Show Monaco Editor if file is uploaded
                  <Editor
                    height="500px"
                    defaultLanguage="python"
                    theme="vs-dark"
                    value={fileContent}
                    onChange={(newValue) => setFileContent(newValue)}
                    options={{
                      autoClosingBrackets: "always",
                      suggestOnTriggerCharacters: true,
                    }}
                  />
                )}
                {/* Display Submit button and script output */}
                {isEditorVisible && (
                  <div className="mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Running Script..." : "Submit Script"}
                    </button>
                  </div>
                )}

                {executionResult && (
                  <div>
                    {executionResult.success ? (
                      <div className="alert alert-success">
                        <h5>{executionResult.message}</h5>
                        <ul>
                          {executionResult.details.map((detail, index) => (
                            <li key={index}>{detail}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="alert alert-danger">
                        <h5>{executionResult.message}</h5>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Display Execution Results */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UploadScript;
