import React from "react";
import { Tooltip } from "react-tooltip";

const TooltipShow = ({ id, content, children, maxWidth = "200px" }) => {
  return (
    <>
      <span data-tip data-for={id} style={{ cursor: "pointer" }}>
        {children}
      </span>

      <Tooltip
        id={id}
        place="top"
        type="dark"
        effect="solid"
        className="custom-tooltip"
        overridePosition={(position) => ({
          ...position,
          width: `calc(${maxWidth} + 10px)`,
        })}
      >
        <div style={{ maxWidth, fontSize: "12px", lineHeight: "20px" }}>
          {" "}
          {content}
        </div>
      </Tooltip>
    </>
  );
};

export default TooltipShow;
